import axios from "./base";

export const sendVerificationCode = (params) => {
  return axios.post("/journey/phone/send", params).then((res) => res.data);
};

export const checkVerificationCode = (data) => {
  return axios.post("/journey/phone/verify", data).then((res) => res.data);
};

export const registerUser = (params) => {
  return axios.post("/journey/user/register", params).then((res) => res.data);
};

export const updateTerms = (id) => {
  return axios.post(`/journey/user/${id}/terms`).then((res) => res.data);
};

export const loginUser = (params) => {
  return axios.post("/journey/authenticate", params).then((res) => res.data);
};

export const googleLogin = (token) => {
  return axios
    .post("/journey/authenticate/google", { token })
    .then((res) => res.data);
};

export const googleRegister = (token) => {
  return axios
    .post("/journey/user/register/google", { token })
    .then((res) => res.data);
};

export const disablePasskey = (id) => {
  return axios
    .post(`/journey/passkey/${id}`, { _method: "delete" })
    .then((res) => res.data);
};

export const getNotifications = () => {
  return axios.get("/journey/notifications").then((res) => res.data);
};

export const getNotificationCount = () => {
  return axios.get("/journey/notifications/count").then((res) => res.data);
};

export const markNotificationsAsRead = () => {
  return axios.post("/journey/notifications").then((res) => res.data);
};

export const checkValidEmail = (email) => {
  return axios.post("/journey/email/valid", { email }).then((res) => res.data);
};

export const checkValidPhone = (phone) => {
  return axios.post("/journey/phone/valid", { phone }).then((res) => res.data);
};
