import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { getErrorLabels } from "utils/errors";
import { googleLogin, loginUser } from "services";
import { setUser, setRegisterData } from "store/actions/auth";
import Error from "components/Error";
import Webpass, { webpassBase } from "services/webpass";

import { ReactComponent as AppleIcon } from "assets/images/icons/apple.svg";
import { ReactComponent as EmailIcon } from "assets/images/icons/email.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icons/phone.svg";
import { ReactComponent as KeyIcon } from "assets/images/icons/key_icon.svg";
import { ReactComponent as FingerprintIcon } from "assets/images/icons/fingerprint.svg";
import { ReactComponent as FaceIdIcon } from "assets/images/icons/faceid.svg";
import googleIcon from "assets/images/icons/google.png";

const LoginForm = (props) => {
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState({});
  const [loginMethod, setLoginMethod] = useState("phone");
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [supportsPasskeys, setSupportsPasskeys] = useState(false);

  const navigate = useNavigate();

  const phoneValidation = yup.object({
    phone: yup
      .string()
      .matches(/^(44|0)7[0-9]{9}$/, "Please enter a valid phone number")
      .required(),
  });

  const emailValidation = yup.object({
    email: yup.string().email("Please enter a valid email address").required(),
  });

  useEffect(() => {
    setSupportsPasskeys(Webpass.isSupported());
  }, []);

  const handleLoginChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = () => {
    let schema;

    if (loginMethod === "phone") {
      schema = phoneValidation;
    } else {
      schema = emailValidation;
    }

    const payload = {
      [loginMethod]: loginData[loginMethod],
    };

    schema
      .validate(payload, { abortEarly: false })
      .then((res) => {
        loginUser(payload)
          .then((res) => {
            if (res.found) {
              props.onProceed(payload, res);
            } else {
              let registerData = { ...payload };

              if (payload?.phone) {
                registerData.phoneRO = true;
                registerData.mobile = payload.phone;
              }

              if (payload?.email) {
                registerData.emailRO = true;
              }

              dispatch(setRegisterData(registerData));
              navigate("/register");
            }
          })
          .catch((err) => {
            setError(err.response.data.message ?? err.message);
          });
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
      });
  };

  const handleGoogleLoginSuccess = (data) => {
    googleLogin(data.access_token)
      .then((res) => {
        props.onProceed({}, res);
      })
      .catch((err) => {
        setError(err.response.data.message ?? err.message);
      });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => handleGoogleLoginSuccess(response),
    onError: (error) => console.log("error", error),
  });

  const handlePasskeyLogin = async () => {
    const { user, success, error, token } = await webpassBase().assert(
      "/journey/webauthn/login/options",
      "/journey/webauthn/login"
    );

    if (success) {
      dispatch(setUser(user));

      props.onPasskeyLogin(token);
    } else {
      alert("We are able to login with the passkey provided. Please try again");
    }
  };

  return (
    <div className="guest-page-register">
      <div className="guest-content-register">
        <div className="guest-title-register">Sign in to your account</div>

        <div className="form-container-register">
          <div className="form-item">
            {loginMethod === "phone" ? (
              <>
                <label className="thin">Telephone</label>
                <input
                  type="phone"
                  placeholder="Enter your telephone"
                  name="phone"
                  onChange={handleLoginChange}
                />
                <Error errors={errors} name="phone" />
              </>
            ) : (
              <>
                <label className="thin">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  onChange={handleLoginChange}
                />
                <Error errors={errors} name="email" />
              </>
            )}

            {error && <p className="form-error">{error}</p>}
          </div>

          <div className="form-item">
            <button className="light-blue full" onClick={handleLogin}>
              Login
            </button>
          </div>

          <div className="or-container">Or</div>

          <div className="buttons-list">
            {loginMethod === "phone" ? (
              <button
                onClick={(e) => setLoginMethod("email")}
                className="login-button full"
              >
                <EmailIcon />
                <p>Sign in with Email</p>
              </button>
            ) : (
              <button
                onClick={(e) => setLoginMethod("phone")}
                className="login-button full"
              >
                <PhoneIcon />
                <p>Sign in with Telephone</p>
              </button>
            )}

            <button className="login-button full" onClick={handleGoogleLogin}>
              <img src={googleIcon} />
              <p>Sign in with Google</p>
            </button>

            {supportsPasskeys && (
              <button
                className="login-button full"
                onClick={handlePasskeyLogin}
              >
                <FaceIdIcon />
                <FingerprintIcon />
                <p>Sign in with Passkey</p>
              </button>
            )}

            {/*
            <button className="outline full">
              <AppleIcon />
              <p>Sign in with Apple</p>
            </button>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
