import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { getOptionsArray } from "utils/options";
import { getErrorLabels } from "utils/errors";
import ButtonOptions from "components/input/ButtonOptions";
import Error from "components/Error";
import SelectInput from "components/input/SelectInput";

const Occupation = (props) => {
  const [data, setData] = useState({
    job_months: "",
    job_years: "",
  });
  const [errors, setErrors] = useState({});

  const metaData = useSelector((state) => state?.general?.metaData);

  const schema = yup.object({
    employment_type: yup.string().required(),
    job_years: yup
      .number()
      .test(
        "total-duration",
        "Job years must be more than 1 month and less than 64 years",
        function (value) {
          let totalMonths =
            parseInt(value) * 12 + parseInt(this.parent.job_months);

          return totalMonths <= 768 && totalMonths > 1;
        }
      )
      .required(),
    job_months: yup
      .number()
      .min(0)
      .max(11)
      .test(
        "total-duration",
        "Job years must be more than 1 month and less than 64 years",
        function (value) {
          let totalMonths =
            parseInt(this.parent.job_years) * 12 + parseInt(value);

          return totalMonths <= 768 && totalMonths > 1;
        }
      )
      .required(),
    income: yup
      .number()
      .min(5000, "This looks low. Please enter your annual income before tax")
      .required(),
  });

  const handleChange = (e) => {
    let value = e.target.value;

    if (e.target.name === "income") {
      value = value.replace(/[^0-9]/g, "");
    }

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleNumberChange = (e) => {
    if (e.target.value.match(/^[0-9]+$/) || e.target.value === "") {
      handleChange(e);
    } else {
      return false;
    }
  };

  const handleButtonChange = (name, val) => {
    setData({
      ...data,
      [name]: val,
    });
  };

  const onProceed = () => {
    schema
      .validate(data, { abortEarly: false })
      .then((res) => {
        props.onUpdate(data);
        props.onProceed();
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
        console.log("err", err.inner);
      });
  };

  useEffect(() => {
    setData({
      job_years: "",
      job_months: "",
      ...props.data,
    });
  }, [props.data]);

  return (
    <div className="guest-page-form">
      <div className="guest-title">Occupation</div>

      <div className="guest-content full-height">
        <div className="form-container">
          <div className="form-questions">
            <div className="form-item">
              <label className="dark">What’s your employment status?</label>
              <ButtonOptions
                options={getOptionsArray(metaData.employmentStatusList)}
                show={3}
                selected={data.employment_type}
                name="employment_type"
                onClick={(name, option) =>
                  handleButtonChange(name, option.value)
                }
                tabIndex={1}
              />

              <Error errors={errors} name="employment_type" />

              <span className="form-item-info">
                <OverlayTrigger
                  placement="top-start"
                  overlay={
                    <Tooltip>
                      Please choose the employment status that reflects your
                      current work situation.
                    </Tooltip>
                  }
                  delay={{ show: 250, hide: 400 }}
                >
                  <div className="flex items-center gap-2">
                    <InfoIcon />
                    More information on employment status
                  </div>
                </OverlayTrigger>
              </span>
            </div>

            <div className="form-item">
              <label className="dark">
                How long have you been in this situation?
              </label>
              <div className="period-container">
                <div className="period-input">
                  <label style={{ marginBottom: 0, fontSize: 12 }}>Years</label>
                  <input
                    name="job_years"
                    placeholder="YY"
                    type="text"
                    maxLength={2}
                    onChange={handleNumberChange}
                    value={data?.job_years}
                    tabIndex={2}
                  />
                </div>
                <div className="period-input">
                  <label style={{ marginBottom: 0, fontSize: 12 }}>
                    Months
                  </label>
                  <input
                    name="job_months"
                    placeholder="MM"
                    type="text"
                    maxLength={2}
                    onChange={handleNumberChange}
                    value={data?.job_months}
                    tabIndex={3}
                  />
                </div>
              </div>

              <Error errors={errors} name={["job_years", "job_months"]} />
            </div>

            <div className="form-item">
              <label className="dark">
                What’s your annual income before tax? (per year)
              </label>
              <input
                type="text"
                name="income"
                onChange={handleChange}
                value={data?.income ?? ""}
                tabIndex={4}
              />

              <Error errors={errors} name="income" />

              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>
                    This is the amount you get paid from your employment before
                    the deduction of tax. This includes your salary, pension and
                    any benefits before tax, including any employment bonuses
                    accumulated.
                  </Tooltip>
                }
                delay={{ show: 250, hide: 400 }}
              >
                <span className="form-item-info">
                  <InfoIcon />
                  More information on annual income
                </span>
              </OverlayTrigger>
            </div>
          </div>

          <div className="form-item mt-auto">
            <button
              className="light-blue full"
              onClick={onProceed}
              tabIndex={5}
            >
              {props.profile ? (
                <>Save changes</>
              ) : (
                <>Next - Personal information</>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Occupation;
