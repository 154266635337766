import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { getErrorLabels } from "utils/errors";
import { getOptionsArray } from "utils/options";
import ButtonOptions from "components/input/ButtonOptions";
import Error from "components/Error";
import SelectInput from "components/input/SelectInput";

const DrivingInfo = (props) => {
  const [data, setData] = useState({
    license_type: "",
    vehicle_use: "",
  });
  const [errors, setErrors] = useState({});

  const metaData = useSelector((state) => state?.general?.metaData);

  const schema = yup.object({
    license_type: yup
      .mixed()
      .oneOf(metaData?.drivingLicenseTypeList)
      .required(),
    vehicle_use: yup.mixed().oneOf(metaData?.vehicleUseList).required(),
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleButtonChange = (name, val) => {
    setData({
      ...data,
      [name]: val,
    });
  };

  const onProceed = () => {
    schema
      .validate(data, { abortEarly: false })
      .then((res) => {
        props.onUpdate(data);
        props.onProceed();
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
        console.log("err", getErrorLabels(err));
      });
  };

  return (
    <div className="guest-page-form">
      <div className="guest-title">Driving information</div>

      <div className="guest-content full-height">
        <div className="form-container">
          <div className="form-item">
            <label className="dark">
              Do you have a full U.K. driving licence?
            </label>
            <ButtonOptions
              options={[
                {
                  label: "Yes",
                  value: "Full UK",
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
              selected={data?.license_type}
              name="license_type"
              onClick={(name, option) => handleButtonChange(name, option.value)}
              tabIndex={1}
            />

            {!Object.keys(data).includes("license_type") && (
              <Error errors={errors} name="license_type" />
            )}

            <OverlayTrigger
              placement="top-start"
              overlay={
                <Tooltip>
                  Lenders require this information to provide you with a quote.
                  Some lenders may not be able to provide you with a quote
                  depending on your driving license type.
                </Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <span className="form-item-info">
                <InfoIcon />
                Why do we need this information?
              </span>
            </OverlayTrigger>
          </div>

          {Object.keys(data).includes("license_type") &&
            data?.license_type !== "Full UK" && (
              <div className="form-item">
                <label className="dark">
                  If you do not have a full U.K. driving licence, please select
                  the license you have?
                </label>
                <SelectInput
                  name="license_type"
                  value={data?.license_type}
                  onChange={handleChange}
                  options={getOptionsArray(metaData?.drivingLicenseTypeList, [
                    "Full UK",
                  ])}
                  showBlank
                  tabIndex={2}
                />

                <Error errors={errors} name="license_type" />
              </div>
            )}

          <div className="form-item">
            <label className="dark">How will your vehicle be used?</label>
            <SelectInput
              name="vehicle_use"
              onChange={handleChange}
              options={getOptionsArray(metaData?.vehicleUseList)}
              showBlank
              value={data?.vehicle_use}
              tabIndex={3}
            />

            <Error errors={errors} name="vehicle_use" />
            <OverlayTrigger
              placement="top-start"
              overlay={
                <Tooltip>
                  Private: Private vehicles are owned and used by individuals
                  for personal purposes
                  <br />
                  Commercial: Commercial vehicles are used for business purposes
                  <br />
                  Taxi: Taxis are vehicles specifically used to transport
                  passengers for a fare, typically on-demand.
                </Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <span className="form-item-info">
                <InfoIcon />
                Different uses explained
              </span>
            </OverlayTrigger>
          </div>

          <div className="form-item mt-auto">
            <button
              className="light-blue full"
              onClick={onProceed}
              tabIndex={4}
            >
              {props.profile ? <>Save changes</> : <>Next - Occupation</>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrivingInfo;
