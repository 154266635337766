import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { getErrorLabels } from "utils/errors";
import { getOptionsArray } from "utils/options";
import Error from "components/Error";
import SelectInput from "components/input/SelectInput";

const Banking = (props) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const metaData = useSelector((state) => state?.general.metaData);

  const sc1 = useRef();
  const sc2 = useRef();
  const sc3 = useRef();

  const validationSchema = yup.object({
    bankAccountNumber: yup
      .string()
      .matches(/[0-9]{8}/, "Please enter a valid account number")
      .required(),
    nameOnAccount: yup.string().required(),
    sortCode: yup
      .string()
      .matches(/[0-9]{2}-[0-9]{2}-[0-9]{2}/, "Please enter a valid sort code")
      .required(),
  });

  useEffect(() => {
    let localData = {
      ...props.data,
    };

    if (localData.sortCode) {
      let parts = localData.sortCode.split("-");

      localData = {
        ...localData,
        sortCode_1: parts[0] ?? "",
        sortCode_2: parts[1] ?? "",
        sortCode_3: parts[2] ?? "",
      };
    }

    setData(localData);
  }, [props.data]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newData = {
      ...data,
    };

    if (name.match(/^sortCode_/)) {
      let sortCodeValue = `${sc1.current.value}-${sc2.current.value}-${sc3.current.value}`;

      newData = {
        sortCode: sortCodeValue,
        [name]: value,
      };
    } else {
      newData = {
        [name]: value,
      };
    }

    setData(newData);
    props.onChange(newData);
  };

  const handleProceed = () => {
    validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        props.onProceed();
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
      });
  };

  return (
    <div className="checkout-page">
      <div className="checkout-title">Banking details</div>
      <div className="page-subtext mb-4">
        Please enter the bank details from which you will be making your monthly
        payments.
      </div>

      <div className="checkout-content full-height">
        <div className="form-container">
          <div className="form-item">
            <label className="dark">Name on account</label>
            <input
              name="nameOnAccount"
              onChange={handleChange}
              value={data?.nameOnAccount}
            />

            <Error errors={errors} name="nameOnAccount" />
          </div>

          <div className="form-item">
            <label className="dark">Sort code</label>

            <Row className="sort-code-row">
              <Col xs={4} md={3}>
                <input
                  name="sortCode_1"
                  onChange={handleChange}
                  maxLength={2}
                  ref={sc1}
                  value={data?.sortCode_1}
                />
              </Col>
              <Col xs={4} md={3}>
                <input
                  name="sortCode_2"
                  onChange={handleChange}
                  maxLength={2}
                  ref={sc2}
                  value={data?.sortCode_2}
                />
              </Col>
              <Col xs={4} md={3}>
                <input
                  name="sortCode_3"
                  onChange={handleChange}
                  maxLength={2}
                  ref={sc3}
                  value={data?.sortCode_3}
                />
              </Col>
            </Row>
            <Error errors={errors} name="sortCode" />
          </div>

          <div className="form-item pb-4">
            <label className="dark">Account number</label>
            <input
              name="bankAccountNumber"
              onChange={handleChange}
              value={data?.bankAccountNumber}
              maxLength={8}
            />
            <Error errors={errors} name="bankAccountNumber" />
          </div>

          <div className="form-item form-button">
            <button className="light-blue w-100" onClick={handleProceed}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banking;
