import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { formatCurrency } from "utils/currency";
import { getErrorLabels } from "utils/errors";
import { getOptionsArray } from "utils/options";
import ButtonOptions from "components/input/ButtonOptions";
import Error from "components/Error";
import SelectInput from "components/input/SelectInput";

const Affordability = (props) => {

  const [ data, setData ] = useState({});
  const [ errors, setErrors ] = useState([]);

  const validationSchema = yup.object({
    curcumstancesChange: yup.boolean().required(),
    numberOfDependants: yup.number().min(0).required(),
    affordCheck: yup.boolean().required(),
  });

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleChange = (name, value) => {
    let newVal = value;

    if (name === "numberOfDependants") {
      newVal = parseInt(value);
    }

    let newData = {
      ...data,
      [name]: newVal,
    };

    setData(newData);
    props.onChange(newData);
  }

  const handleProceed = () => {
    validationSchema.validate(data, { abortEarly: false })
      .then(() => {
        props.onProceed()
      }).catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  return (
    <div className="checkout-page">
      <div className="checkout-title">Your affordability</div>

      <div className="checkout-content full-height">
        <div className="form-container">
          <div className="form-item">
            <label className="dark">
              Do you think your circumstances are likely to change at all during 
              the term of your finance agreement?
            </label>
            <ButtonOptions
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              name="curcumstancesChange"
              selected={data?.curcumstancesChange}
              onClick={(name, val) => handleChange(name, val.value)}
            />
            <Error name="curcumstancesChange" errors={errors} />
          </div>

          <div className="form-item">
            <label className="dark">
              How many people depend on you financially?
            </label>

            <SelectInput
              name="numberOfDependants"
              options={getOptionsArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])}
              value={data?.numberOfDependants}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />
            <Error name="numberOfDependants" errors={errors} />
          </div>

          <div className="form-item pb-4">
            <label className="dark">
              Do you believe that you will be able to comfortably afford the 
              repayments ({ formatCurrency(props.quote.payments) }pm) for 
              the entire duration of the term of the finance agreement?
            </label>
            <ButtonOptions
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              name="affordCheck"
              selected={data?.affordCheck}
              onClick={(name, value) => handleChange(name, value.value)}
            />
            <Error name="affordCheck" errors={errors} />
          </div>

          <div className="form-item form-button">
            <button className="light-blue w-100" onClick={handleProceed}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Affordability;
